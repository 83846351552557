<template>
  <div class="main-cnt statement-container">
    <el-row :gutter="10">
      <el-col :span="2">
        <el-select v-model="formData.projectId" placeholder="请选择项目">
          <el-option
            :label="item.p_name"
            :value="item.p_id"
            v-for="item in projectOptions"
            :key="item.p_id"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="6">
        <el-row>
          <el-col :span="5" class="text-center">
            <span>支付时间</span>
          </el-col>
          <el-col :span="9">
            <el-date-picker
              :clearable="false"
              v-model="formData.stime"
              type="date"
              placeholder="支付时间开始"
              @change="changeStime"
            />
          </el-col>
          <el-col :span="1" class="text-center">
            <span>-</span>
          </el-col>
          <el-col :span="9">
            <el-date-picker
              :clearable="false"
              v-model="formData.etime"
              type="date"
              placeholder="支付时间结束"
              @change="changeEtime"
            />
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4">
        <el-select
          v-if="!showEchart"
          v-model="queryData.agentId"
          multiple
          collapse-tags
          clearable
          placeholder="请选择代理商"
        >
          <el-option
            :label="item.a_name"
            :value="item.a_id"
            v-for="item in agentOptions"
            :key="item.a_id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          v-if="!showEchart"
          v-model="queryData.payway"
          multiple
          clearable
          collapse-tags
          placeholder="请选择支付方式"
        >
          <el-option
            :label="item.orp_name"
            :value="item.orp_id"
            v-for="item in orderTypeOptions"
            :key="item.orp_id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4"></el-col>
      <el-col :span="5" class="action-btn">
        <template v-if="!showEchart">
          <el-tooltip
            class="item"
            effect="dark"
            content="导出"
            placement="left"
          >
            <span class="border-icon" @click="handleExport">
              <i class="iconfont icon-a-lujing207"></i
            ></span>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="打印"
            placement="left"
          >
            <span class="border-icon mr-10" @click="handlePrint">
              <i class="iconfont icon-a-lujing206"></i
            ></span>
          </el-tooltip>
          <el-button @click="handleReset">重置</el-button>
        </template>
        <el-button type="primary" @click="handleQuery">{{
          showEchart ? "明细" : "统计"
        }}</el-button>
      </el-col>
    </el-row>
    <div class="content">
      <AgentEcharts
        v-if="showEchart"
        :queryData="lastEchartQuery"
        @paywayClick="paywayClick"
        @agentClick="agentClick"
      ></AgentEcharts>
      <AgentTable
        v-if="!showEchart"
        ref="scenicTableRef"
        :queryData="lastTableQuery"
      ></AgentTable>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, reactive, computed } from "vue";
import AgentEcharts from "../components/AgentEcharts.vue";
import AgentTable from "../components/AgentTable.vue";
import { BasicApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import dayjs from "dayjs";
export default {
  components: {
    AgentEcharts,
    AgentTable,
  },
  setup() {
    onMounted(() => {
      getOrderType();
      getStoreData();
      getProjectData();
    });
    const showEchart = ref(true);
    const formData = reactive({
      projectId: "",
      stime: new Date(),
      etime: new Date(),
    });
    const queryData = ref({
      agentId: [],
      payway: [],
    });
    const projectOptions = ref([]);
    const getProjectData = () => {
      BasicApi.projectSelect().then((res) => {
        if (res.Code === 200) {
          if (res.Data.length) {
            projectOptions.value = res.Data;
            if (!formData.projectId) {
              formData.projectId = projectOptions.value[0].p_id;
            }
          }
        } else {
          let msg = res.Message ? res.Message : "获取数据失败！";
          ElMessage.error(msg);
        }
      });
    };
    const orderTypeOptions = ref([]);
    const getOrderType = () => {
      BasicApi.paywayOptions().then((res) => {
        if (res.Code === 200) {
          orderTypeOptions.value = res.Data;
        } else {
          let msg = res.Message ? res.Message : "获取支付方式失败！";
          ElMessage.error(msg);
        }
      });
    };

    const agentOptions = ref([]);
    function getStoreData() {
      BasicApi.agentOptions().then((res) => {
        if (res.Code === 200) {
          if (res.Data.length) {
            agentOptions.value = res.Data;
          }
        } else {
          let msg = res.Message ? res.Message : "获取代理商数据失败！";
          ElMessage.error(msg);
        }
      });
    }

    function changeStime(data) {
      if (formData["etime"] && dayjs(formData["etime"]).isBefore(dayjs(data))) {
        //如果结束时间有值 且 在开始时间之前
        formData["stime"] = formData["etime"];
        formData["etime"] = data;
      } else {
        formData["stime"] = data;
      }
    }
    function changeEtime(data) {
      if (formData["stime"] && dayjs(formData["stime"]).isAfter(dayjs(data))) {
        formData["etime"] = formData["stime"];
        formData["stime"] = data;
      } else {
        formData["etime"] = data;
      }
    }
    const handleQuery = () => {
      showEchart.value = !showEchart.value;
      if (showEchart.value) {
        queryData.value = {
          agentId: [],
          payway: [],
        };
      }
    };
    function handleReset() {
      formData["stime"] = new Date();
      formData["etime"] = new Date();
      queryData.value = {
        agentId: [],
        payway: [],
      };
    }
    function paywayClick(data) {
      queryData.value.payway = [data];
      queryData.value.agentId = [];
      showEchart.value = false;
    }
    function agentClick(data) {
      queryData.value.agentId = [data];
      queryData.value.payway = [];
      showEchart.value = false;
    }
    const lastTableQuery = computed(() => {
      return {
        ...queryData.value,
        stime: dayjs(formData.stime).format("YYYY-MM-DD"),
        etime: dayjs(formData.etime).format("YYYY-MM-DD"),
        projectId: formData.projectId,
      };
    });
    const lastEchartQuery = computed(() => {
      return {
        stime: dayjs(formData.stime).format("YYYY-MM-DD"),
        etime: dayjs(formData.etime).format("YYYY-MM-DD"),
        projectId: formData.projectId,
      };
    });
    const scenicTableRef = ref(null);
    function handleExport() {
      scenicTableRef.value.handleExport();
    }
    function handlePrint() {
      scenicTableRef.value.handlePrint();
    }
    return {
      handleQuery,
      formData,
      projectOptions,
      changeEtime,
      changeStime,
      queryData,
      orderTypeOptions,
      paywayClick,
      agentClick,
      handleReset,
      lastTableQuery,
      showEchart,
      handleExport,
      handlePrint,
      scenicTableRef,
      lastEchartQuery,
      agentOptions,
    };
  },
};
</script>

<style lang="scss">
.main-cnt {
  height: auto !important;
}
</style>

